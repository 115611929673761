import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import Logo from "../components/Logo"
import Name from "../components/Name"

import * as S from '../styles/404';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" description="Error 404" />
    <S.Container>
      <S.Main>
        <S.DivLogo>
          <S.Logo>
            <Logo />
          </S.Logo>
          <S.Name>
            <Name />
          </S.Name>
        </S.DivLogo>
        <S.Separator />
        <S.Content>
          <S.DivTitle>
            <S.TextTitle>404</S.TextTitle>
            <S.TextTitleSub>Page Not Found</S.TextTitleSub>
          </S.DivTitle>
          <S.DivContacts>
            <S.Text>Oops... there's nothing here</S.Text>
            <S.TextLink to="/">Go HOME</S.TextLink>
          </S.DivContacts>
        </S.Content>
      </S.Main>
      <S.Temp>
        <S.TextTitleSub>[ Site under development ]</S.TextTitleSub>
      </S.Temp>
    </S.Container>
  </Layout>
)

export default NotFoundPage
